var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-position": "top",
          },
        },
        [
          _vm.currentTitle === "延期说明" || _vm.currentTitle === "预定说明"
            ? [
                _c(
                  "el-row",
                  { attrs: { gutter: 48 } },
                  [
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c("div", { staticClass: "cusInfo" }, [
                        _c("p", [_vm._v("客户名称")]),
                        _c("p", [
                          _vm._v(_vm._s(_vm.processData.enterpriseName)),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c("div", { staticClass: "cusInfo" }, [
                        _c("p", [_vm._v("销售人员")]),
                        _c("p", [_vm._v(_vm._s(_vm.processData.employeeName))]),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 48 } },
                  [
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c("div", { staticClass: "cusInfo" }, [
                        _c("p", [_vm._v("销售电话")]),
                        _c("p", [
                          _vm._v(_vm._s(_vm.processData.employeePhone)),
                        ]),
                      ]),
                    ]),
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c("div", { staticClass: "cusInfo" }, [
                        _c("p", [_vm._v("创建时间")]),
                        _c("p", [_vm._v(_vm._s(_vm.processData.creationDate))]),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 48 } },
                  [
                    _c("el-col", { attrs: { span: 24 } }, [
                      _vm.processData.type == "2"
                        ? _c("div", { staticClass: "cusInfo" }, [
                            _c("p", [_vm._v("延期原因")]),
                            _c("p", { staticClass: "cusReason" }, [
                              _vm._v(_vm._s(_vm.processData.reason)),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.processData.type == "1"
                        ? _c("div", { staticClass: "cusInfo" }, [
                            _c("p", [_vm._v("预定原因")]),
                            _c("p", { staticClass: "cusReason" }, [
                              _vm._v(_vm._s(_vm.processData.reason)),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.currentTitle === "提示"
            ? [
                _c(
                  "el-row",
                  { attrs: { gutter: 48 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "驳回原因",
                              prop: "reasonRejection",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { type: "textarea" },
                              model: {
                                value: _vm.ruleForm.reasonRejection,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "reasonRejection", $$v)
                                },
                                expression: "ruleForm.reasonRejection",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c(
            "el-form-item",
            {
              staticStyle: { "margin-top": "60px" },
              attrs: { align: "right" },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "plain" },
                  on: {
                    click: function ($event) {
                      return _vm.clickStep("close")
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm.currentTitle === "延期说明" || _vm.currentTitle === "预定说明"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.clickStep("agree")
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.processData.type == 2 ? "同意延期" : "同意预定"
                        )
                      ),
                    ]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.clickStep("reject")
                        },
                      },
                    },
                    [_vm._v("提交")]
                  ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }