<template>
    <div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="top">
            <template v-if="currentTitle === '延期说明' || currentTitle === '预定说明'">
                <el-row :gutter="48">
                    <el-col :span="12">
                        <div class="cusInfo">
                            <p>客户名称</p>
                            <p>{{ processData.enterpriseName }}</p>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="cusInfo">
                            <p>销售人员</p>
                            <p>{{ processData.employeeName }}</p>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="48">
                    <el-col :span="12">
                        <div class="cusInfo">
                            <p>销售电话</p>
                            <p>{{ processData.employeePhone }}</p>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="cusInfo">
                            <p>创建时间</p>
                            <p>{{ processData.creationDate }}</p>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="48">
                    <el-col :span="24">
                        <div class="cusInfo" v-if="processData.type == '2'">
                            <p>延期原因</p>
                            <p class="cusReason">{{ processData.reason }}</p>
                        </div>
                        <div class="cusInfo" v-if="processData.type == '1'">
                            <p>预定原因</p>
                            <p class="cusReason">{{ processData.reason }}</p>
                        </div>
                    </el-col>
                </el-row>
            </template>
            <template v-if="currentTitle === '提示'">
                <el-row :gutter="48">
                    <el-col :span="24">
                        <el-form-item label="驳回原因" prop="reasonRejection">
                            <el-input type="textarea" v-model="ruleForm.reasonRejection"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </template>
            <el-form-item align="right" style="margin-top: 60px;">
                <el-button type="plain" @click="clickStep('close')">取消</el-button>
                <el-button v-if="currentTitle === '延期说明' || currentTitle === '预定说明'" type="primary"
                    @click="clickStep('agree')">{{
            processData.type == 2 ? "同意延期" : "同意预定" }}</el-button>
                <el-button v-else type="primary" @click="clickStep('reject')">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import { envInfo } from "@/constants/envInfo";
import {
    getprossListDetail,
    operatePass,
    operateReject
} from "@/api/ruge/gsPark/customerService/customerRelaManagement";
export default {
    props: {
        dialogStatus: {
            type: String,
            required: true,
        },
        id: {
            required: false,
        },
        dialogTitle: {
            type: String,
            required: true,
        },
        customerId: {
            required: false,
        }
    },
    data() {
        return {
            ruleForm: {
                reasonRejection: '',

            },
            rules: {
                reasonRejection: [
                    { required: true, message: '必填项', trigger: 'change' },
                    { max: 150, message: '最多输入150个字符', trigger: 'change' }
                ]
            },
            currentTitle: null,
            processData: {}
        }
    },
    methods: {
        clickStep(type) {
            if (type == 'close') {
                this.$emit("close", false);
            } else if (type == "agree") {
                operatePass({
                    id: this.id,
                    customerId: this.processData.customerId,
                    type: this.processData.type
                }).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '操作成功!'
                        });
                        this.$emit("close", true);
                    } else if (res.code == 424) {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        });
                    } else if (res.code == 500) {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        });
                    }
                })
            } else if (type == "reject") {
                this.$refs["ruleForm"].validate((valid) => {
                    if (valid) {
                        operateReject({
                            id: this.id,
                            customerId: this.processData.customerId,
                            type: this.processData.type,
                            reason: this.ruleForm.reasonRejection
                        }).then(res => {
                            if (res.code == 200) {
                                this.$message({
                                    type: 'success',
                                    message: '操作成功!'
                                });
                                this.$emit("close", true);
                            } else if (res.code == 424) {
                                this.$message({
                                    message: res.msg,
                                    type: 'error'
                                });
                            } else if (res.code == 500) {
                                this.$message({
                                    message: res.msg,
                                    type: 'error'
                                });
                            }
                        })
                    }
                })

            }
        },
        getDetailId(id) {
            getprossListDetail({ id }).then(res => {
                console.log(res, '详情');
                this.processData = res.data
            })
        }
    },
    watch: {
        id: {
            handler(id) {
                if (!id) return;
                this.getDetailId(id)
            },
            immediate: true,
        },
        customerId: {
            handler(customerId) {
                if (!customerId) return;
            },
            immediate: true,
        },
        dialogStatus: {
            handler(val) {
                this.currentState = val;
            },
            immediate: true,
        },
        dialogTitle: {
            handler(val) {
                if (!val) return;
                this.currentTitle = val;
            },
            immediate: true,
        }
    }
}

</script>
<style>
.cusInfo {
    margin-bottom: 24px;

    :nth-child(1) {
        color: #6d747a;
        font-size: 12px;
    }

    :nth-child(2) {
        color: #2F3941;
        margin-top: 4px;
        font-size: 14px;
    }

    .cusReason {
        width: 660px;
        background: #F8F8F8;
        padding: 12px 0;
        padding-left: 12px;
        border-radius: 4px 4px 4px 4px;
    }
}
</style>