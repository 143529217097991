<template>
    <div class="app-container">
        <div class="filter-line">
            <span>
                <el-button icon="el-icon-refresh-right" @click="getTableList()">{{ this.$t("commons.refresh")
                    }}</el-button>
                <el-button icon="el-icon-download" @click="exportHandler" v-if="havePermissions.includes(
                    'gcrm$Service$tenantCrmManagement$exportCustomerInfo'
                )">{{ this.$t("commons.export") }}</el-button>
                <!-- <el-button icon="el-icon-upload2" @click="uploadModel = true">{{ this.$t("commons.import")
                    }}</el-button>
                <el-button icon="el-icon-upload" @click="downHighseasTemplate">导入模板</el-button> -->
            </span>
        </div>
        <div>
            <finalTable ref="finalTableRef" :datas="dataset" @tableEventHandler="tableEventHandler">
                <template v-slot:typeStr="row">
                    <span v-if="row.datas.typeStr">
                        <span class="single-time-span">
                            <span>
                                {{ row.datas.typeStr }}
                            </span>
                        </span>
                    </span>
                </template>
                <template v-slot:reviewStatusStr="row">
                    <span v-if="row.datas.reviewStatusStr">
                        <span class="single-time-span">
                            <span>
                                {{ row.datas.reviewStatusStr }}
                            </span>
                        </span>
                    </span>
                </template>
            </finalTable>
        </div>
        <el-dialog :visible.sync="dialogVisible" :before-close="handleClose" width="728px">
            <template slot="title">
                <span>{{ highSeasList.dialogTitle }}</span>
            </template>
            <processListDetail @close="handleClose" :id="highSeasList.id" :dialogStatus="highSeasList.dialogStatus"
                :dialogTitle="highSeasList.dialogTitle" v-if="dialogVisible">
            </processListDetail>
        </el-dialog>
        <!--导入文件-->
        <el-dialog :visible.sync="uploadModel" width="40%">
            <template slot="title">
                <title-icon />{{ $t("commons.uploadFile") }}
            </template>
            <div slot="footer">
                <upload :ulType="uploadType" :excelType="excelType" :rootPath="rootPath" @success="uploadSuccess"
                    @fail="uploadFail"></upload>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import finalTable from "@/components/FinalTable";
import processListDetail from "./processListDetail.vue"
import {
    downHighseasTemplate
} from "@/api/business/base/tenant/materiel";
import {
    publicCustomerList,
    deleteCustomers,
    publicCustomerListExport,

    getprossList,
    approvalExport

} from "@/api/ruge/gsPark/customerService/customerRelaManagement";
import { envInfo } from "@/constants/envInfo";
import Upload from "@/components/Upload";
import { mapGetters } from "vuex";
export default {
    components: {
        finalTable,
        processListDetail,
        Upload
    },
    data() {
        return {
            uploadModel: false,
            uploadType: "ExcelImport",
            rootPath: envInfo.bgApp.personnelmanagePath,
            excelType: "ruge.customer",
            dataset: {
                paginationConfig: {
                    need: true,
                },
                filterLine: {
                    show: true,
                },
                header: [
                    { prop: "type", label: "类型", width: "" },
                    { prop: "enterpriseName", label: "客户名称", width: "" },
                    { prop: "employeePhone", label: "销售电话", width: "" },
                    { prop: "employeeName", label: "销售人员", width: "" },
                    { prop: "reviewStatus", label: "状态", width: "" },
                    { prop: "operation", label: "操作", width: "180" },
                ],
                tableData: [],
                // 搜索行配置
                searchLineConfig: {
                    type: {
                        type: "selector",
                        label: "类型",
                        value: "",
                        optionList: [
                            {
                                label: "预定",
                                value: '1',
                            },
                            {
                                label: "延期",
                                value: '2',
                            }
                        ],
                        actionType: "goSearch",
                    },
                    enterpriseName: {
                        type: "input",
                        label: "客户名称",
                        value: "",
                        actionType: "goSearch",
                        placeholder: "请输入客户名称",
                        prefixIcon: "el-icon-search",
                    },
                    employeePhone: {
                        type: "input",
                        label: "销售电话",
                        value: "",
                        actionType: "goSearch",
                        placeholder: "请输入销售电话",
                        prefixIcon: "el-icon-search",
                    },
                    employeeName: {
                        type: "input",
                        label: "销售人员",
                        value: "",
                        actionType: "goSearch",
                        placeholder: "请输入销售人员",
                        prefixIcon: "el-icon-search",
                    },
                    reviewStatus: {
                        type: "selector",
                        label: "状态",
                        value: "",
                        optionList: [
                            {
                                label: "审核中",
                                value: '0',
                            },
                            {
                                label: "通过",
                                value: '1',
                            },
                            {
                                label: "驳回",
                                value: '2',
                            }
                        ],
                        actionType: "goSearch",
                    }
                },
                // 表格内容配置
                detailConfig: {
                    type: {
                        type: "slot",
                        slotName: "typeStr",
                    },
                    reviewStatus: {
                        type: "slot",
                        slotName: "reviewStatusStr",
                    },
                    operation: {
                        type: "icon",
                        iconList: [
                            {
                                actionType: "iconClick",
                                eventName: "view",
                                fontSize: "14px",
                                color: "#1A4CEC",
                                cursorPointer: "pointer",
                                iconName: "el-icon-view",
                                tooltips: "查看详情",
                            },
                            {
                                actionType: "iconClick",
                                eventName: "agree",
                                fontSize: "14px",
                                color: "#1A4CEC",
                                cursorPointer: "pointer",
                                iconName: "el-icon-view",
                                tooltips: "同意",
                                showProp: "reviewStatus",
                                showValue: 0,
                            },
                            {
                                actionType: "iconClick",
                                eventName: "reject",
                                fontSize: "14px",
                                color: "#1A4CEC",
                                cursorPointer: "pointer",
                                iconName: "el-icon-view",
                                tooltips: "驳回",
                                showProp: "reviewStatus",
                                showValue: 0,
                            },
                        ],
                    }
                },
                // 高级搜索配置
                advanceFilterConfig: {},
                pageVO: {
                    current: 1,
                    rowCount: 10,
                    total: 0,
                },
            },
            dialogVisible: false,
            highSeasList: {
                dialogStatus: 'share',
                dialogTitle: "分配客户",
            },
            personnelObj: {
                tableLoading: false,
                listQuery: {
                    current: 1,
                    rowCount: 10,
                    // type: 2
                }
            }
        }
    },
    created() {
        this.getTableList()
    },
    computed: {
        ...mapGetters(["havePermissions"]),
    },
    methods: {
        getTableList() {
            console.log('111');
            this.personnelObj.tableLoading = true;
            const params = { ...this.personnelObj.listQuery };
            const hasDeletePermision = this.havePermissions.includes('gcrm$Service$tenantCrmManagement$deleteCustomerInfo');
            const hasSharePermision = this.havePermissions.includes('gcrm$Service$tenantCrmManagement$assignCustomer');
            this.setOperationWidth(hasDeletePermision)
            getprossList(params).then(res => {
                if (res.code == 200) {
                    res.data.rows.forEach((item) => {
                        this.$set(item, 'statusKey', hasDeletePermision)
                    });
                    res.data.rows.forEach((item) => {
                        this.$set(item, 'statuskey', hasSharePermision)
                    });
                    this.dataset.pageVO.total = res.data.total;
                    this.dataset.tableData = res.data.rows;
                } else if (res.code == 500) {
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    });
                }
            })
                .finally(() => {
                    this.personnelObj.tableLoading = false;
                });
        },
        setOperationWidth(hasDeletePermision) {
            this.dataset.header.forEach(item => {
                if (item.prop === 'operation') {
                    item.width = hasDeletePermision ? '180' : '80'
                }
            })
        },
        tableEventHandler(datas) {
            if (datas.type === "goSearch") {
                this.personnelObj.listQuery = {
                    ...this.personnelObj.listQuery,
                    ...datas.params,
                };
                this.personnelObj.listQuery.current = 1;
                this.dataset.pageVO.current = 1;
                this.getTableList();
            } else if (datas.type === "paginationChange") {
                this.personnelObj.listQuery.current = datas.params.current.page;
                this.personnelObj.listQuery.rowCount = datas.params.current.limit;
                this.getTableList();
            } else if (datas.type === "iconClick") {
                switch (datas.eventName) {
                    case "view":
                        // this.dialogVisible = true;
                        // this.highSeasList.id = datas.row.id;
                        // this.highSeasList.customerId = datas.row.customerId;
                        // this.highSeasList.dialogStatus = "view";
                        // this.highSeasList.dialogTitle = "查看详情";
                        this.singleViewHandler(datas.row)

                        break;
                    case "agree":
                        this.dialogVisible = true;
                        this.highSeasList.id = datas.row.id;
                        this.highSeasList.customerId = datas.row.customerId;
                        this.highSeasList.dialogStatus = "agree";
                        datas.row.type == 2 ? this.highSeasList.dialogTitle = "延期说明" : this.highSeasList.dialogTitle = "预定说明";
                        break;
                    case "reject":
                        this.dialogVisible = true;
                        this.highSeasList.id = datas.row.id;
                        this.highSeasList.customerId = datas.row.customerId;
                        this.highSeasList.dialogStatus = "reject";
                        this.highSeasList.dialogTitle = "提示";
                }
            }
        },
        downHighseasTemplate() {
            downHighseasTemplate();
        },
        uploadSuccess() {
            this.uploadModel = false;
            // this.$message({
            //   type: "success",
            //   message: this.$t("message.uploadSuccess"),
            // });
        },
        uploadFail() {
            this.$message({ type: "error", message: this.$t("message.uploadFial") });
        },
        singleDeleteHandler(row) {
            this.$confirm(
                this.$t("message.deleteConfirm"),
                this.$t("commons.warning"),
                {
                    confirmButtonText: this.$t("commons.confirm"),
                    cancelButtonText: this.$t("commons.cancel"),
                    type: "warning",
                }
            ).then(() => {
                console.log(row, 'row');
                deleteCustomers({
                    id: row.id
                }).then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.getTableList()
                    } else if (res.code == 424) {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        });
                    } else if (res.code == 500) {
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        });
                    }
                })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            })
        },
        handleClose(freshFlag) {
            console.log('关闭');
            this.dialogVisible = false
            freshFlag && this.getTableList();
        },
        singleViewHandler(row) {
            this.$router.push({
                path: "/gsPark/customerRelaManagement/customerList/customerListDetail",
                query: { id: row.customerId },
            });
        },
        // 导出
        exportHandler() {
            approvalExport(this.personnelObj.listQuery).then((taskId) => {
                this.$message({
                    type: "success",
                    message: this.$t("message.operationSuccess"),
                });
                let exportObj = {
                    taskId,
                    taskName: "审批",
                    taskStatus: 0,
                    rootPath: "gcrm",
                };
                this.$store.dispatch("PushExportNotice", exportObj);
            });
        },
    },
    watch: {
        personnelObj: {
            handler(val) {
                this.$refs.finalTableRef.loadingToggle(val.tableLoading);
            },
            deep: true,
        }
    }
}
</script>
<style lang="less" scoped>
.filter-line {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
}
</style>